<script setup lang="ts">
import Loader from '~/components/shared/loader.vue'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { getTrackDistantApiData } from '~/helpers/distantApi/getTrackDistantApi'

type Props = {
  trackUrl: string
  showBiggerPlaceholder?: boolean
  loaderHasNoZIndex?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showBiggerPlaceholder: false,
  loaderHasNoZIndex: false,
})

const { coreFetch } = useProvideCoreFetch()

const thumbnailCache: Record<string, string | undefined> = {}

const imageSrc = ref<string | null>(null)
const loaded = ref(false)

async function fetchImageSrc() {
  if (thumbnailCache[props.trackUrl] !== undefined) {
    imageSrc.value = thumbnailCache[props.trackUrl] ?? null
    loaded.value = true
  } else {
    const resp = await getTrackDistantApiData(coreFetch, props.trackUrl)

    if (!resp.err) {
      thumbnailCache[props.trackUrl] = resp.thumbnail ?? undefined
      imageSrc.value = resp.thumbnail || null
    } else {
      imageSrc.value = null
    }

    loaded.value = true
  }
}

onMounted(fetchImageSrc)

watch(
  () => props.trackUrl,
  () => {
    imageSrc.value = ''
    fetchImageSrc()
  },
)
</script>

<template>
  <div
    class="tw-relative tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-xs"
  >
    <transition mode="out-in" name="fade">
      <Loader
        v-if="!imageSrc && !loaded"
        key="loader"
        class="tw-bg-fill"
        :class="{ '!tw-z-0': loaderHasNoZIndex }"
      />
      <img
        v-else-if="imageSrc"
        key="img"
        loading="lazy"
        class="tw-h-full tw-w-full tw-overflow-hidden tw-rounded-xs tw-object-cover tw-object-center"
        :src="imageSrc"
        alt=""
      />
      <LogoIcon
        v-else
        key="placeholder"
        :size="showBiggerPlaceholder ? 'xl' : 'lg'"
        class="tw-contrast-50 tw-filter"
        aria-hidden
      />
    </transition>
  </div>
</template>
